<template>
  <el-dialog title="调整基金池金额-可能失效" :visible.sync="dialogVisible" width="79%" :before-close="handleClose">
    <el-form v-model="editFundFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="银行编码" width="120px">
            <el-input width="20px" v-model="editFundFrom.bankCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="支行名称" width="120px">
            <el-input width="20px" v-model="editFundFrom.subbranch" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="存款类型" width="120px">
            <el-input width="20px" v-model="editFundFrom.bankType" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="未投入金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.noPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="本次投入金额" width="120px">
            <el-input-number
              width="20px"
              v-model="editFundFrom.money"
              @change="editFundFromButton()"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="修改后的金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.afterPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="21">
          <el-form-item label="备注" width="120px">
            <el-input width="20px" v-model="editFundFrom.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="editFundMoneyButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatDate } from "@/plugins/data.js";
import { getSelectById, editFundMoney } from "@/api/capital/bank/bank";
export default {
  name: "editFundMoney",
  data() {
    return {
      dialogVisible: false,
      editFundFrom: {}
    };
  },
  methods: {
    //打开弹窗
    showFund(id) {
      console.log(id);
      this.dialogVisible = true;
      this.getSelectByIdButton(id);
    },
    getSelectByIdButton(id) {
      getSelectById(id).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        this.editFundFrom = res.result;
      });
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    editFundFromButton() {
      var newNoPutMoney = parseInt(this.editFundFrom.noPutMoney);
      this.editFundFrom.afterPutMoney = newNoPutMoney + this.editFundFrom.money;
      if (this.editFundFrom.afterPutMoney < 0) {
        this.editFundFrom.money = 0;
        this.editFundFrom.afterPutMoney = 0;
        return this.$message.error("剩余金额不支持扣减");
      }
    },
    editFundMoneyButton() {
      if (this.editFundFrom.money < 0) {
        if (this.editFundFrom.remark == null) {
          return this.$message.error("负数的时候备注需要填入");
        }
      }

      editFundMoney(this.editFundFrom).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        console.log(res);
        this.dialogVisible = false;
        this.$parent.getBankList();
      });
    }
  }
};
</script>
<style lang="less" scoped>
</style> 